const questions = [
  {
    options: ["A", "B", "C", "D"],
    answer: "B",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "D",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "A",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "C",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "A",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "A",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "B",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "D",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "D",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "C",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "C",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "B",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "C",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "B",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "C",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "D",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "A",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "A",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "A",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "A",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "D",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "B",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "B",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "C",
  },
  {
    options: ["A", "B", "C", "D"],
    answer: "D",
  },
];

export default questions;
